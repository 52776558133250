exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ /* Position the language select over the language icon, and make it transparent */ .language-selector_language-select_1h0tC {\n    position: absolute;\n    width: 3rem;\n    height: 3rem;\n    opacity: 0;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: .875rem;\n    color: hsla(225, 15%, 40%, 1);\n    background: hsla(0, 100%, 100%, 1);\n} [dir=\"ltr\"] .language-selector_language-select_1h0tC {\n    right: 0;\n} [dir=\"rtl\"] .language-selector_language-select_1h0tC {\n    left: 0;\n} .language-selector_language-select_1h0tC option {\n    opacity: 1;\n} .language-selector_language-select_1h0tC:focus {\n    border: 1px solid white;\n}\n", ""]);

// exports
exports.locals = {
	"language-select": "language-selector_language-select_1h0tC",
	"languageSelect": "language-selector_language-select_1h0tC"
};