import React from 'react';
import {FormattedMessage} from 'react-intl';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

import superbotUsbIconURL from './superbotUsb/superbotusb.png';
import superbotUsbInsetIconURL from './superbotUsb/superbotusb-small.png';
import superbotUsbConnectionIconURL from './superbotUsb/superbotusb-illustration.svg';
import superbotUsbConnectionSmallIconURL from './superbotUsb/superbotusb-small.png';
import superbotUsbConnectionTipIconURL from './superbotUsb/superbotusb-button-illustration.gif';

import mithonccBluetoothIconURL from './mithonccBluetooth/mithonccBluetooth.jpg';
import mithonccBluetoothInsetIconURL from './mithonccBluetooth/mithonccBluetooth-small.png';
import mithonccBluetoothConnectionIconURL from './mithonccBluetooth/mithonccBluetooth-illustration.svg';
import mithonccBluetoothConnectionSmallIconURL from './mithonccBluetooth/mithonccBluetooth-small.png';
import mithonccBluetoothConnectionTipIconURL from './mithonccBluetooth/mithonccBluetooth-button-illustration.gif';


export default [
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    }
    ,{
        name: 'Mithon_CC',
        extensionId: 'superbotUsb',
        collaborator: '齐道机器人',
        iconURL: superbotUsbIconURL,
        insetIconURL: superbotUsbInsetIconURL,
        description: '一体化的机器人',
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: superbotUsbConnectionIconURL,
        connectionSmallIconURL: superbotUsbConnectionSmallIconURL,
        connectionTipIconURL: superbotUsbConnectionTipIconURL,
        connectingMessage: '正在连接',
        helpLink: 'https://www.mixio.org'
    }
    ,{
        name: (
            <FormattedMessage
                defaultMessage="蓝牙连接的Mithon_CC"
                description="Name for the 'Mithon_CC' extension"
                id="gui.extension.mithonccBluetooth.name"
            />
        ),
        extensionId: 'mithonccBluetooth',
        collaborator: (
            <FormattedMessage
                defaultMessage="齐道机器人"
                description="Collaborator for the 'Mithon_CC' extension"
                id="gui.extension.mithonccBluetooth.collaborator"
            />
        ),
        iconURL: mithonccBluetoothIconURL,
        insetIconURL: mithonccBluetoothInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="一体化的机器人"
                description="Description for the 'Mithon_CC' extension"
                id="gui.extension.mithonccBluetooth.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: mithonccBluetoothConnectionIconURL,
        connectionSmallIconURL: mithonccBluetoothConnectionSmallIconURL,
        connectionTipIconURL: mithonccBluetoothConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="正在连接"
                description="Message to help people connect to their Mithon_CC."
                id="gui.extension.mithonccBluetooth.connectingMessage"
            />
        ),
        helpLink: 'https://www.mixio.org'
    }
];
